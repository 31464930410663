import React from "react"

import OverviewComponent from "../OverviewComponent"

export default function Diasoft({ data }) {
  let i = 0
  return [
    <section className="bgBlock bgImage Diasoft" key={i++}>
      <div className="transformBlock-1"></div>
      <div className="transformBlock-2"></div>
      <div className="transformBlock-3"></div>
      <div className="container">
        <div className="row">
          <div
            className="col-md-10 col-sm-8 col-md-offset-1 col-sm-offset-2"
            style={{ height: "100%" }}
          >
            <div className="titleBlock" id='diasoft-header'>
              <div className="iconLogo">
                <img src={data.images.logo} alt="Diasoft application" />
              </div>
              <h1 className="diasoft-subheader">{data.subtitle}</h1>
            </div>
          </div>
        </div>
      </div>
    </section>,

    <section className="Diasoft compose" key={i++}>
      <div className="bgImage">
        <OverviewComponent {...data} />

        <section className="DiasoftImg image">
          <div className="imageBlock">
            <div className="transformBlock-4"></div>
            <div className="transformBlock-5"></div>
            <div className="container">
              <div className="row">
                <img src={data.images.section2} alt="Diasoft application" />
              </div>
            </div>
            <div className="transformBlock-6"></div>
            <div className="transformBlock-7"></div>
          </div>
        </section>

        <section className="solution">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <h2 className="noMarginTop">{data.solution.title}</h2>
                {data.solution.textFirst.map((item, i) => (
                  <p className="mb-4" key={i}>{item}</p>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="image-solution image">
          <div className="transformBlock-8"></div>
          <div className="transformBlock-9"></div>
          <div className="container">
            <div className="row">
              <img
                src={data.images.section3}
                alt="Diasoft application"
                className="solution-picture"
              />
            </div>
          </div>
          <div className="transformBlock-10"></div>
          <div className="transformBlock-11"></div>
        </section>

        <section className="solution last">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <h2 className="noMarginTop">{data.delivered.title}</h2>
                <ul>
                  {data.delivered.list.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>,
  ]
}
