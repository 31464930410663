import { Logo, Picture1, Picture2 } from "../../images/SingleCase/Diasoft"

const dataForDiasoft = {
  type: "project",
  smart: true,
  classComponent: "Diasoft",
  nextLink: "cases/pickup-sport/",
  prevLink: "cases/RZD-roads/",
  data: {
    title: "Diasoft",
    subtitle:
      "Complex system targeted for automation of financial institutions, such as banks management and investment companies, open-end funds, etc., credit non-financial organizations operating on a single chart of accounts and whose activities are regulated by regulatory enactments.",
    overview: {
      title: "Project overview",
      about: {
        title: "About client",
        content:
          "Diasoft is one of the largest developers and suppliers of IT solutions for the financial sector, recognized by world experts (Gartner, IDC, BIAN, etc.). Company's product portfolio is not limited to IT systems for banks, the company creates automated solutions for insurance companies, private pension funds, investment and management companies, microfinance organizations and other financial institutions.",
      },
      challenge: {
        title: "Business challenge",
        content:
          "As a main company’s speciality is automation the business challenge was - automation of the separate modules business processes, as well as good UX, UI implementation, flexibility of the system and reporting systems for different financial institutions. The project covered the insurance companies modules (desktop) and banking (web).",
      },
      specific: [
        {
          type: "Platform",
          content: ["Web"],
          icon: "fa-th-large",
        },
        {
          type: "Technology",
          content: ["Java", "XML", "JavaScript", "JBoss", "COM/DCOM"],
          icon: "fa-cog",
        },
        {
          type: "Team",
          icon: "fa-users",
          content: [
            {
              type: "Project Manager",
              count: 1,
            },
            {
              type: "Developers",
              count: 5,
            },
            {
              type: "QA Engineer",
              count: 1,
            },
            {
              type: "Designer",
              count: 1,
            },
          ],
        },
        {
          type: "Duration",
          icon: "fa-calendar",
          content: [
            {
              type: "18 months",
              count: "",
            },
          ],
        },
        {
          type: "Client",
          content: ["Enterprise"],
          icon: "fa-user",
        },
      ],
    },
    solution: {
      title: "Solution",
      textFirst: [
        "The applications were built by Service-Oriented Architecture and represents a set of services that implement business functionality, and a business process management system, responsible for the orchestration of these services, as well as third-party applications within a single business process.",
        "We used J2EE architecture to create high-performance industrial solution for the system. The use of industrial technologies makes it possible to provide platform independence, the ability to work in heterogeneous environments, unsurpassed scalability and system availability in 24x7 mode.",
        "For independent creation/editing of new forms and new processes, the visual editor of screen forms and business processes was integrated. To create and edit new reporting forms, iReport software was used, which is part of the complex of front-office solution administration tools.",
        "The report editor allows to customize the visual presentation of report forms based on the data set returned by the services of the system. At the same time, directly in the body of the report, there is possibility to specify the logic that controls the structure of the report and the order in which the data is displayed (the dynamic structure of the report).",
      ],
    },
    delivered: {
      title: "Value delivered",
      list: [
        "Possibility to independently custom the business processes without any code interference",
        "Transparent integration of system components with any other applications (ABS, CRM, scoring systems, processing, etc.) within the framework of a cross business-process",
        "A significant reduction of time to market of new product for any customer or modifications of existing ones",
        "Visual editors allow to customize the solution to people who are not IT professionals",
        "The software components are separated from the business configuration (metamodel) and can be modified independently of each other. Changing the kernel version does not lead to the loss of self-developed work (reports, screen forms, processes, etc.)",
        "Possibility of updating the system just by to replacing the metamodel with the configuration description (copy several files)",
        "Web and desktop realization provides variety of opportunities for any specific customer needs ",
      ],
    },
    images: {
      logo: Logo,
      section2: Picture1,
      section3: Picture2,
    },
  },
  imageSrc: [],
  relatedProjects: [
    {
      link: "/cases/karpatska-chaika",
      id: "seagull",
      title: "Carpathian Seagull",
    },
    {
      link: "/cases/crm-sap-integration",
      id: "crmSap",
      title: "CRM/SAP Integration",
    },
    {
      link: "/cases/gud-job",
      id: "gud-job",
      title: "Gud Job",
    },
    {
      link: "/cases/video-conference",
      id: "video-conference",
      title: "Video Conference App",
    },
  ],
  link: "/cases/diasoft",
}

export default dataForDiasoft
